import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { saveFilters } from '../../../../redux/actions/filters';
import { useNavigate } from 'react-router-dom';
import './infoFilter.scss';
import { BiArrowBack } from 'react-icons/bi';

const InfoFilter = ({ infoPieza }) => {
    const { marca, modelo, familia, nombre } = infoPieza;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleSaveFilters = (filterBy) => {
        const filterValues = {
            marca: { id: infoPieza.id_marca, nombre: marca },
            modelo: { id: '', nombre: '' },
            familia: { id: '', nombre: '' },
            articulo: { id: '', nombre: '' },
            precioMin: '',
            precioMax: ''
        };
        if (filterBy === 'modelo') {
            filterValues.modelo = { id: infoPieza.id_modelo, nombre: modelo };
        } else if (filterBy === 'familia') {
            filterValues.modelo = { id: infoPieza.id_modelo, nombre: modelo };
            filterValues.familia = {
                id: infoPieza.familia_id,
                nombre: familia
            };
        } else if (filterBy === 'tipo_pieza') {
            filterValues.modelo = { id: infoPieza.id_modelo, nombre: modelo };
            filterValues.familia = {
                id: infoPieza.familia_id,
                nombre: familia
            };
            filterValues.articulo = { id: infoPieza.tipo_pieza_id, nombre };
        }
        dispatch(saveFilters({ filterValues, currentPage: 1 }));
        return navigate('/');
    };

    return (
        <div className="info-filter">
            <p className="info-filter__container">
                <BiArrowBack
                    className="info-filter__container--back"
                    onClick={() => navigate(-1)}
                />
                <span
                    className="info-filter__container--clickable"
                    onClick={() => handleSaveFilters('marca')}
                >
                    {marca?.toLowerCase()}
                </span>
                <span>›</span>
                <span
                    className="info-filter__container--clickable"
                    onClick={() => handleSaveFilters('modelo')}
                >
                    {modelo?.toLowerCase()}
                </span>
                <span>›</span>
                <span
                    className="info-filter__container--clickable"
                    onClick={() => handleSaveFilters('familia')}
                >
                    {familia?.toLowerCase()}
                </span>
                <span>›</span>
                <span
                    className="info-filter__container--clickable"
                    onClick={() => handleSaveFilters('tipo_pieza')}
                >
                    {nombre?.toLowerCase()}
                </span>
            </p>
        </div>
    );
};

InfoFilter.propTypes = {
    infoPieza: PropTypes.object.isRequired
};

export default InfoFilter;
