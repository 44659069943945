/* eslint-disable quote-props */
/* eslint-disable quotes */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import WhatsAppSection from '../../shared/WhatsAppSection';

import './piezaDetalle.scss';
import ImgCarousel from './ImgCarousel';
import InfoDetalle from './InfoDetalle';
import { apiRequest } from '../../../services/api';
import SkeletonInfoDetalle from './SkeletonInfoDetalle';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { updatePropertyMetaTag } from '../../../utils';
import ExtraInfoText from '../../shared/ExtraInfoText';
import InfoFilter from './InfoFilter';
import RelatedParts from './RelatedParts';

const PiezaDetalle = () => {
    const { t, i18n } = useTranslation();
    const { piezaId } = useParams();
    const [infoPieza, setInfoPieza] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const apiReqPiezaById = async () => {
        setIsLoading(true);
        const res = await apiRequest.stock.getPiezaById(piezaId);
        if (res?.success) {
            setInfoPieza(res.part);
        } else {
            toast.error(t('errors.1'));
        }
        return setIsLoading(false);
    };

    const getAvailabilityJsonLd = (stateId) => {
        if (stateId === 0) return 'https://schema.org/SoldOut';
        if (stateId === 1) return 'https://schema.org/InStock';
        if (stateId === 3) return 'https://schema.org/Reserved';
        return 'https://schema.org/SoldOut';
    };

    useEffect(() => {
        apiReqPiezaById();
        window.scrollTo(0, 0);
    }, [piezaId]);

    useEffect(() => {
        const metaTag = document.querySelector(`meta[name="description"]`);
        const link = document.querySelector('link[rel="canonical"]');
        let scriptTag;

        updatePropertyMetaTag('og:locale', i18n.language);

        const removeMetaTag = (property) => {
            const meta = document.querySelector(`meta[property="${property}"]`);
            if (meta) meta.remove();
        };

        if (infoPieza) {
            const title = `${infoPieza?.referencia} ${infoPieza?.marca} ${infoPieza?.modelo} ${infoPieza?.nombre}, ${(infoPieza?.precio_venta).toFixed(2)} €`;
            const description = t('head.description-detail', {
                marca: infoPieza?.marca,
                modelo: infoPieza?.modelo,
                nombre: infoPieza?.nombre,
                referencia: infoPieza?.referencia
            });
            const img = infoPieza?.imgs?.split(',')[0] || '';

            document.title = title;
            metaTag.content = description;
            updatePropertyMetaTag('og:title', title);
            updatePropertyMetaTag('og:description', description);
            updatePropertyMetaTag('og:image', img);
            updatePropertyMetaTag('og:image:secure_url', img);
            updatePropertyMetaTag('og:type', 'product');
            updatePropertyMetaTag('og:url', window.location.href);
            updatePropertyMetaTag(
                'product:price.amount',
                (infoPieza?.precio_venta).toFixed(2)
            );
            updatePropertyMetaTag('product:price.currency', 'EUR');
            link.href = window.location.href;
            updatePropertyMetaTag('og:site_name', t('head.site-name'));

            scriptTag = document.createElement('script');
            scriptTag.type = 'application/ld+json';
            const jsonLD = {
                '@context': 'https://schema.org',
                '@type': 'Product',
                name: title,
                description,
                image: img,
                offers: {
                    '@type': 'Offer',
                    url: window.location.href,
                    priceCurrency: 'EUR',
                    price: (infoPieza?.precio_venta).toFixed(2),
                    itemCondition: 'https://schema.org/UsedCondition',
                    availability: getAvailabilityJsonLd(infoPieza?.estado),
                    shippingDetails: {
                        '@type': 'OfferShippingDetails',
                        shippingWeight: {
                            '@type': 'QuantitativeValue',
                            value: infoPieza?.peso,
                            unitCode: 'KG'
                        }
                    }
                },
                brand: { '@type': 'Brand', name: infoPieza?.marca },
                weight: {
                    '@type': 'QuantitativeValue',
                    value: infoPieza?.peso,
                    unitCode: 'KG'
                },
                sku: infoPieza?.referencia.split(' ')[0],
                aggregateRating: {
                    '@type': 'AggregateRating',
                    ratingValue: 4.9,
                    ratingCount: 1382,
                    reviewCount: 994
                }
            };
            scriptTag.text = JSON.stringify(jsonLD);
            document.body.appendChild(scriptTag);
        }

        return () => {
            if (infoPieza) {
                document.title = t('head.title');

                metaTag.content = t('head.description');
                updatePropertyMetaTag('og:title', t('head.og-title'));
                updatePropertyMetaTag(
                    'og:description',
                    t('head.og-description')
                );

                updatePropertyMetaTag('og:type', 'website');
                updatePropertyMetaTag('og:url', 'https://piezas.recomotor.com');

                link.href = 'https://piezas.recomotor.com';
                updatePropertyMetaTag('og:site_name', 'Recomotor');

                removeMetaTag('og:image:secure_url');
                removeMetaTag('og:image');
                removeMetaTag('product:price.amount');
                removeMetaTag('product:price.currency');

                document.body.removeChild(scriptTag);
            }
        };
    }, [infoPieza, i18n.language]);

    return (
        <div className="pieza-detalle">
            <div className="pieza-detalle__header">
                {infoPieza && <InfoFilter infoPieza={infoPieza} />}
            </div>
            <div className="pieza-detalle__container">
                <ImgCarousel
                    fotos={infoPieza?.imgs?.split(',')}
                    isLoading={isLoading}
                    altImg={`${infoPieza?.nombre} ${infoPieza?.marca} ${infoPieza?.modelo}`}
                />
                {!isLoading
                    ? <InfoDetalle pieza={infoPieza} />
                    : <SkeletonInfoDetalle />
                }
            </div>
            {infoPieza && <RelatedParts infoPieza={infoPieza} />}
            <ExtraInfoText
                marca={infoPieza?.marca}
                modelo={infoPieza?.modelo}
                pieza={infoPieza?.nombre}
                familia={infoPieza?.familia}
            />
            <WhatsAppSection className="pieza-detalle__whatsapp" />
        </div>
    );
};

export default PiezaDetalle;
