import { Link } from 'react-router-dom';
import logo from '../../../images/recomotor_logo_footer.png';
import insta from '../../../images/instagram.png';
import linkedin from '../../../images/linkedin.png';
import './footer.scss';
import { useTranslation } from 'react-i18next';
import config from '../../../config';
import bizum from '../../../images/footer/bizum.png';
import transfer from '../../../images/footer/transfer2.png';
import visa from '../../../images/footer/visa.png';
import mastercard from '../../../images/footer/mastercard.png';
import mrw from '../../../images/footer/mrwBlanco.png';
import seur from '../../../images/footer/seurBlanco.png';
import dhl from '../../../images/footer/dhlBlanco.png';
import txt from '../../../images/footer/txtBlanco.png';

export default function Footer (
) {
    const { t } = useTranslation();

    return (
        <>
            <div className='payment'>
                <div><img className='visa' src={visa} alt='visa'/></div>
                <div><img className='mastercard' src={mastercard} alt='mastercard'/></div>
                <div><img className='transfer' src={transfer} alt='transfer'/></div>
                <div><img className='bizum' src={bizum} alt='bizum'/></div>
            </div>
            <div className="footer">
                <div className="footer__container">
                    <div className="footer-wrapper">
                        <img src={logo} className="footer-wrapper__img" alt="Recomotor" title={config.app.version} />

                        <div>
                            <a href="https://recomotor.com/vende-para-desguace" target="_blank" rel="noreferrer">
                                {t('footer.link-vende')}
                            </a>
                        </div>
                    </div>

                    <div className="footer-wrapper">
                        <h3>{t('footer.title.1')}</h3>

                        <div>
                            <a className='inicio' href="https://recomotor.com" target="_blank" rel="noreferrer">
                                {t('footer.link-inicio')}
                            </a>

                            <a className='blog' href="https://recomotor.com/blog" target="_blank" rel="noreferrer">
                                {t('footer.link-blog')}
                            </a>

                            <a className='trabaja' href="https://recomotor.com/trabaja-con-nosotros" target="_blank" rel="noreferrer">
                                {t('footer.link-trabaja')}
                            </a>
                        </div>
                    </div>

                    <div className="footer-wrapper">
                        <h3>{t('footer.title.3')}</h3>

                        <div>
                            <Link to={'/contacto'} title="Contacto">
                                {t('footer.link-contacto')}
                            </Link>
                        </div>
                    </div>

                    <div className="footer-wrapper">
                        <h3>{t('footer.title.2')}</h3>

                        <div className='shipping'>
                            <img className='mrw' src={mrw} alt='mrw'/>
                            <img className='dhl' src={dhl} alt='dhl'/>
                            <img className='seur' src={seur} alt='seur'/>
                            <img className='txt' src={txt} alt='txt'/>
                        </div>
                    </div>

                    <div className="footer-wrapper">
                        <h3>{t('footer.title.4')}</h3>
                        <div className='footer-wrapper__container'>
                            <p>{t('footer.text-recomotor')}</p>

                            <div className="social-media-content">
                                <a href="https://es.linkedin.com/company/recomotor" className="m-0 me-2" target="_blank" rel="noreferrer">
                                    <img className='social-media-content__linkedin' src={linkedin} alt="Linkedin" />
                                </a>

                                <a href="https://www.instagram.com/recomotor" className="m-0" target="_blank" rel="noreferrer">
                                    <img className='social-media-content__instagram' src={insta} alt="Instagram" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-info">
                    <small className="copyright">© Copyright {new Date().getFullYear()} Recomotor</small>
                    <div className="footer-info-privacity">
                        <div>
                            <a href="https://recomotor.com/aviso-legal" target="_blank" rel="noreferrer">
                                {t('footer.link-legal')}
                            </a>
                        </div>
                        <div>
                            <a href="https://recomotor.com/politica-de-privacidad" target="_blank" rel="noreferrer">
                                {t('footer.link-privacidad')}
                            </a>
                        </div>
                        <div>
                            <a href="https://recomotor.com/politica-de-cookies" target="_blank" rel="noreferrer">
                                {t('footer.link-cookies')}
                            </a>
                        </div>
                        <div>
                            <Link to={'/compra'} title="Condiciones generales">
                                {t('footer.link-condiciones')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
