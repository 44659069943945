import DetalleCarrito from '../../shared/DetalleCarrito';
import { BiArrowBack } from 'react-icons/bi';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import './resumenCarrito.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import WhatsAppSection from '../../shared/WhatsAppSection';
import TotalPedido from '../../shared/TotalPedido';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { apiRequest } from '../../../services/api';
import { updateCart } from '../../../redux/actions/cart';
import Spinner from '../../shared/Spinner';

const ResumenCarrito = () => {
    const { t } = useTranslation();
    const { setIsOpenAuthModal, origin } = useOutletContext();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);
    const user = useSelector((state) => state.auth);
    const [piezasStatusChecked, setPiezasStatusChecked] = useState([]);
    const [isLoading, setIsLoading] = useState();

    const submitOrder = async () => {
        if (isLoading) return;
        if (cart.piezas.length === 0) return;
        const isStockNoDisponible = piezasStatusChecked.filter(
            (part) => part?.estado !== 1 && part?.estado !== 3
        );
        if (isStockNoDisponible?.length > 0) return toast.error(t('errors.stock.1'));
        if (piezasStatusChecked?.length === 0) return toast.error(t('errors.stock.2'));

        if (!user) return setIsOpenAuthModal('guest');

        setIsLoading(true);
        const res = await apiRequest.cart.createUserCheckout({
            piezas: cart.piezas,
            userInfo: user,
            origin
        });
        if (res?.success) {
            const link = document.createElement('a');
            link.href = res.checkoutUrl;
            setIsLoading(false);
            return link.click();
        }
        toast.error(t('carrito.detalle.error'));
        return setIsLoading(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const total = cart.piezas.reduce(
            (acc, act) => acc + act.precio_venta,
            0
        );
        const totalIva = Number((total * 0.21 + total).toFixed(2));
        const newCart = {
            ...cart,
            total,
            total_iva: totalIva,
            iva: 21,
            validado: true,
            total_casco: 0
        };
        dispatch(updateCart(newCart));
    }, [cart.piezas]);

    return (
        <div className='resumen-carrito'>
            <section
                className={`resumen-carrito__detalle ${
                    cart.piezas.length > 0 ? '' : 'empty-cart'
                }`}
            >
                <div className='resumen-carrito__detalle--return'>
                    <button onClick={() => navigate(-1)}>
                        <BiArrowBack /> {t('carrito.detalle.continuar')}
                    </button>
                </div>
                <h1 className='resumen-carrito__detalle--title'>
                    {t('carrito.detalle.title')}
                </h1>
                <DetalleCarrito
                    piezasStatusChecked={piezasStatusChecked}
                    setPiezasStatusChecked={setPiezasStatusChecked}
                    className='resumen-carrito__detalle--piezas'
                />
            </section>
            {cart.piezas.length > 0 && (
                <section className='resumen-carrito__pedido-section'>
                    <div className='resumen-carrito__pedido-section-div'>
                        <TotalPedido className='resumen-carrito__pedido-section--total' />
                    </div>
                    <button
                        className={
                            cart.piezas.length === 0
                                ? 'resumen-carrito__pedido-section--disabled-btn'
                                : ''
                        }
                        onClick={submitOrder}
                        disabled={isLoading}
                    >
                        <span className='icon-tramitar-pedido'>
                            {isLoading
                                ? <Spinner className='spinner-resumen-carrito' />
                                : <MdOutlineKeyboardArrowRight />
                            }
                        </span>
                        {t('carrito.pedido.submit')}
                    </button>
                </section>
            )}
            <WhatsAppSection />
        </div>
    );
};

export default ResumenCarrito;
