import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './extraInfoText.scss';

const ExtraInfoText = ({ marca, modelo, pieza, familia }) => {
    const { t } = useTranslation();
    const [marcaModelo, setMarcaModelo] = useState('');
    const [marcaModeloFamilia, setMarcaModeloFamilia] = useState('');

    useEffect(() => {
        const marcaMod = [marca, modelo].filter(Boolean).join(' ');
        const marcaModFam = [marca, modelo, familia].filter(Boolean).join(' ');

        setMarcaModelo(marcaMod);
        setMarcaModeloFamilia(marcaModFam);
    }, [marca, modelo, pieza, familia]);

    return (
        <div className='extra-info-text'>
            <h3>
                <Trans
                    i18nKey="extraInfo.header"
                    values={{
                        pieza: pieza || t('extraInfo.defaultPiece'),
                        marcaModeloFamilia
                    }}
                />
            </h3>
            <p>
                <Trans
                    i18nKey="extraInfo.description1"
                    values={{ marcaModelo }}
                />
                {pieza && (
                    <Trans
                        i18nKey="extraInfo.description2"
                        values={{ pieza }}
                    />
                )}
                {t('extraInfo.description3')}
            </p>
            <p>{t('extraInfo.description4')}</p>
            <p>{t('extraInfo.description5')}</p>
        </div>
    );
};

ExtraInfoText.propTypes = {
    marca: PropTypes.string,
    modelo: PropTypes.string,
    pieza: PropTypes.string,
    familia: PropTypes.string
};

export default ExtraInfoText;
