import './skeletonInfoDetalle.scss';
const SkeletonInfoDetalle = () => {
    return <div className='info-detalle skeleton-info-detalle'>
        <div className='info-detalle__container'>
            <p className='info-detalle__container--disponible skeleton-info-detalle__disponible info-detalle-loading-animation'>
            </p>
            <h3 className='info-detalle__container--nombre skeleton-info-detalle__nombre info-detalle-loading-animation'></h3>
            <h4 className='info-detalle__container--modelo skeleton-info-detalle__modelo info-detalle-loading-animation'></h4>
            <p className='info-detalle__container--labels'>
                <span className='skeleton-info-detalle__label1 info-detalle-loading-animation'></span>
                <span className='skeleton-info-detalle__label2 info-detalle-loading-animation'></span>
            </p>
            <p className='info-detalle__container--observaciones skeleton-info-detalle__observaciones info-detalle-loading-animation'></p>
            <p className='info-detalle__container--sku-ref'>
                <span className='info-detalle__container--sku-ref--sku'>
                    <span className='skeleton-info-detalle__sku info-detalle-loading-animation'></span>
                    <span className='skeleton-info-detalle__sku2 info-detalle-loading-animation'></span>
                </span>
            </p>
            <div>
                <p className='skeleton-info-detalle__ info-detalle-loading-animation'></p>
                <p className='skeleton-info-detalle__motor info-detalle-loading-animation'></p>
                <p className='skeleton-info-detalle__combustible info-detalle-loading-animation'></p>
            </div>
            <div>
                <p className='skeleton-info-detalle__año info-detalle-loading-animation'></p>
                <p className='skeleton-info-detalle__km info-detalle-loading-animation'></p>
                <p className='skeleton-info-detalle__puertas info-detalle-loading-animation'></p>
            </div>

            <div className='info-detalle__container--add-cart'>
                <h3 className='skeleton-info-detalle__precio'>
                    <span className='skeleton-info-detalle__precio1 info-detalle-loading-animation'></span>
                    <span className='skeleton-info-detalle__precio2 info-detalle-loading-animation'></span>
                </h3>
                <p className='skeleton-info-detalle__btn info-detalle-loading-animation'>
                </p>
            </div>
        </div>
    </div>;
};

export default SkeletonInfoDetalle;
