import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { apiRequest } from '../../../../services/api';
import './relatedParts.scss';
import RelatedPartCard from './RelatedPartCard';

const RelatedParts = ({ infoPieza }) => {
    const {
        id_marca: marcaId,
        marca,
        id_modelo: modeloId,
        modelo,
        familia_id: familiaId,
        familia,
        tipo_pieza_id: articuloId,
        nombre: articulo
    } = infoPieza;

    const [relatedParts, setRelatedParts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleFilterValues = (filterToReset) => {
        const filterValues = {
            marca: { id: marcaId, nombre: marca },
            modelo: { id: modeloId, nombre: modelo },
            familia: { id: familiaId, nombre: familia },
            articulo: { id: articuloId, nombre: articulo },
            limit: 6,
            page: 1,
            order: 'asc'
        };
        if (filterToReset) {
            filterValues.articulo = { id: '', nombre: '' };
            if (filterToReset === 'familia' || filterToReset === 'modelo') {
                filterValues.familia = { id: '', nombre: '' };
            }
            if (filterToReset === 'modelo') {
                filterValues.modelo = { id: '', nombre: '' };
            }
        }
        return filterValues;
    };

    const getStockRequest = async (filterToReset) => {
        const filterValues = handleFilterValues(filterToReset);
        const res = await apiRequest.stock.stockRequest(filterValues);
        if (res?.success) {
            if (res.parts.length > 2) {
                const results = res.parts.filter(part => part.id !== infoPieza.id);
                setRelatedParts(results);
                return true;
            }
            return false;
        }
        return setRelatedParts(null);
    };

    const getRelatedPartsReq = async () => {
        setIsLoading(true);

        const filters = ['', 'articulo', 'familia', 'modelo'];

        for (const filter of filters) {
            const stock = await getStockRequest(filter);
            if (stock) {
                setIsLoading(false);
                return;
            }
        }

        setRelatedParts(null);
        setIsLoading(false);
    };

    useEffect(() => {
        getRelatedPartsReq();
    }, []);

    return (
        <>
            {relatedParts && (
                <div className="related-parts">
                    <h3 className='related-parts__title'>Piezas relacionadas</h3>
                    <div className={`related-parts__container ${relatedParts.length === 6 ? 'space-between' : ''}`}>
                        {isLoading
                            ? Array.from({ length: 3 }).map((each, idx) => (
                                <div key={idx} className='related-part-card related-parts__skeleton'>
                                    <span className='related-part-card__img related-parts__skeleton--img loading-sk-animation'></span>
                                    <span className='related-parts__skeleton--title loading-sk-animation'></span>
                                    <span className='related-parts__skeleton--text loading-sk-animation'></span>
                                    <span className='related-part-card__info--bottom related-parts__skeleton--bottom'>
                                        <span className='related-parts__skeleton--bottom--price loading-sk-animation'></span>
                                        <span className='related-parts__skeleton--bottom--cart loading-sk-animation'></span>
                                    </span>
                                </div>
                            ))
                            : relatedParts.map((part) => (
                                <React.Fragment key={part.id}>
                                    {part?.precio_venta !== 0 && (
                                        <RelatedPartCard part={part} />
                                    )}
                                </React.Fragment>
                            ))}
                    </div>
                </div>
            )}
        </>
    );
};

RelatedParts.propTypes = {
    infoPieza: PropTypes.object.isRequired
};

export default RelatedParts;
