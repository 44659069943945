import { useState } from 'react';
import AddToCartBtn from '../../../../shared/AddToCartBtn';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Image from '../../../../shared/Image';
import { useNavigate } from 'react-router-dom';
import { limpiarNombre, procesarReferencia } from '../../../../../utils';

const RelatedPartCard = ({ part }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    return (
        <div
            className="related-part-card"
            onClick={() =>
                navigate(
                    `/detalle/${limpiarNombre(part.marca)}/${limpiarNombre(
                        part.modelo
                    )}/${limpiarNombre(part.familia)}/${limpiarNombre(
                        part.nombre
                    )}/${procesarReferencia(part.referencia)}/${part.id}`
                )
            }
        >
            <div className="related-part-card__img">
                <Image foto={part?.imgs?.split(',')[0]} />
            </div>
            <div className="related-part-card__info">
                <div>
                    <h4>{part.nombre}</h4>
                    <h5>
                        {part.marca} {part.modelo}
                    </h5>
                </div>
                <div className="related-part-card__info--bottom">
                    <p className="related-part-card__info--bottom--price">
                        {(part?.precio_venta).toFixed(2) + '€ '}
                        <span>{t('catalogo-piezas.pieza-card.iva')}</span>
                    </p>
                    <AddToCartBtn
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        classNameBtn="related-part-card__info--bottom--cart"
                        classNameSpinner="spinner-pieza-card"
                        classNameDisabled="disabled-to-add"
                        pieza={part}
                    />
                </div>
            </div>
        </div>
    );
};

RelatedPartCard.propTypes = {
    part: PropTypes.object.isRequired
};

export default RelatedPartCard;
