/* eslint-disable quote-props */
/* eslint-disable quotes */
import { RouterProvider } from 'react-router-dom';
import { router } from './routes/routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { updatePropertyMetaTag } from './utils';

function App () {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (window.location.pathname.includes('/detalle/')) return;
        document.title = t('head.title');

        const metaTag = document.querySelector(`meta[name="description"]`);
        metaTag.content = t('head.description');

        updatePropertyMetaTag('og:locale', i18n.language);
        updatePropertyMetaTag('og:title', t('head.og-title'));
        updatePropertyMetaTag('og:description', t('head.og-description'));

        const scriptTag = document.createElement('script');
        scriptTag.type = 'application/ld+json';
        const jsonLD = {
            "@context": "https://schema.org",
            "@type": "OnlineStore",
            "url": "https://www.recomotor.com/",
            "sameAs": [
                "https://www.linkedin.com/company/recomotor",
                "https://www.instagram.com/recomotor/"
            ],
            "image": "https://piezas.recomotor.com/recomotor-logo.png",
            "logo": "https://piezas.recomotor.com/recomotor-logo.png",
            "legalName": "JG RECOMOTOR S.L.",
            "name": "Recomotor",
            "alternateName": ["Recomotor"],
            "description": t('head.og-description'),
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "Polígono dels Frares, Calle D, parcela número 46",
                "addressLocality": "Lleida",
                "postalCode": "25190",
                "addressCountry": "ES"
            },
            "vatID": "B04950093",
            "email": "info@recomotor.com",
            "telephone": "+34 644 175 425",
            "foundingDate": "2021-04-29"
        };
        scriptTag.text = JSON.stringify(jsonLD);

        document.body.appendChild(scriptTag);

        return () => {
            document.body.removeChild(scriptTag);
        };
    }, [t, i18n.language]);

    return (
        <Provider store={store}>
            <RouterProvider router={router} />
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
            />
        </Provider>
    );
}

export default App;
