import Spinner from '../../Spinner';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { apiRequest } from '../../../../services/api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const FormGuest = ({ setIsOpenAuthModal, origin }) => {
    const { t } = useTranslation();
    const cart = useSelector(state => state.cart);
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async () => {
        setIsLoading(true);
        const res = await apiRequest.cart.createGuestCheckout({ piezas: cart.piezas, origin });
        if (res?.success) {
            const link = document.createElement('a');
            link.href = res.checkoutUrl;
            return link.click();
        }
        toast.error(t('carrito.detalle.error'));
        return setIsLoading(false);
    };

    return (
        <div className='form-login form-guest'>
            <div className="form-login__container form-guest">
                <div className="form-login__container--guest-btn">
                    <button type="submit" className='primary-btn' onClick={onSubmit}>
                        { isLoading
                            ? <Spinner className="spinner-contact-form guest" />
                            : t('form-guest.guest')
                        }
                    </button>
                </div>

                <p className="form-login__container--guest">
                    <span></span>
                    {t('form-guest.o')}
                    <span></span>
                </p>

                <div className="form-login__container--guest-btn">
                    <button type="submit" onClick={() => setIsOpenAuthModal('register')}>
                        {t('form-guest.register')}
                    </button>
                </div>
            </div>
        </div>
    );
};

FormGuest.propTypes = {
    setIsOpenAuthModal: PropTypes.func,
    origin: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOf([null])
    ])
};

export default FormGuest;
