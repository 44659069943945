import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ModalContainer from '../ModalContainer';
import FormGuest from './FormGuest';
import FormLogin from './FormLogin';
import FormRegister from './FormRegister';
import FormVerifyRegister from './FormVerifyRegister';
import './authModal.scss';
import useScreenWidth from '../../../hooks/useScreenWidth';
import logo from '../../../images/login/logo2.png';

const AuthModal = ({ setIsOpenAuthModal, origin, formName }) => {
    const [tokenVerifyRegister, setTokenVerifyRegister] = useState('');
    const [email, setEmail] = useState('');
    const screenWidth = useScreenWidth();

    const MemoizedFormGuest = useMemo(() => <FormGuest setIsOpenAuthModal={setIsOpenAuthModal} origin={origin} />, []);
    const MemoizedFormLogin = useMemo(() => <FormLogin setIsOpenAuthModal={setIsOpenAuthModal} />, []);
    const MemoizedFormRegister = useMemo(() => <FormRegister setTokenVerifyRegister={setTokenVerifyRegister} setIsOpenAuthModal={setIsOpenAuthModal} setEmail={setEmail} />, [setTokenVerifyRegister, setIsOpenAuthModal, setEmail]);
    const MemoizedFormVerifyRegister = useMemo(() => <FormVerifyRegister token={tokenVerifyRegister} setIsOpenAuthModal={setIsOpenAuthModal} email={email} setTokenVerifyRegister={setTokenVerifyRegister} />, [tokenVerifyRegister, setIsOpenAuthModal, email, setTokenVerifyRegister]);

    return (
        <ModalContainer
            setIsOpenModal={setIsOpenAuthModal}
            className={`auth-modal ${formName === 'guest' ? 'guest' : ''}`}
        >
            {screenWidth < 576 && (
                <img src={logo} alt="Recomotor" className="auth-modal__logo" />
            )}
            {formName === 'guest' && MemoizedFormGuest}
            {formName === 'login' && MemoizedFormLogin}
            {formName === 'register' && MemoizedFormRegister}
            {formName === 'verify-register' && MemoizedFormVerifyRegister}
        </ModalContainer>
    );
};

AuthModal.propTypes = {
    setIsOpenAuthModal: PropTypes.func,
    formName: PropTypes.string,
    origin: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOf([null])
    ])
};

export default AuthModal;
